import React, { useState, useEffect } from 'react';
import google from './google.png';
import { GoogleLogin } from '@react-oauth/google';
import jwt_decode from "jwt-decode";

const InterestInput = ({ onInterestsSubmit }) => {
  var condition;
  const [interests, setInterests] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [result, setResult] = useState('');
  const [user, setUser] = useState('');
  const [loading, setLoading] = useState('');
  const [credits, setCredits] = useState('');
  const [plan, setPlan] = useState('');

  const responseMessage = async (response) => {
    console.log(response);
    var token = response.credential;
    var decoded = jwt_decode(token);
    fetch("https://rizz-ai-backend.onrender.com/credits", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      }, 
      body: JSON.stringify({ user: decoded.email })
    })
    .then((response) => response.json())
    .then((json) => {
      setUser(decoded.email);
      setCredits(json.creditsRemaining);
      setPlan(json.plan);
      console.log('User is: ', decoded.email)
      // console.log('user is: ', user)
      // console.log('Credits are: ', credits)
      // console.log('credits is: ', credits)
      // setResult(json.text)
      // setFlag(json.flag)
      // setLoading(false);
    });
    // setUser(decoded.email);
};
const errorMessage = (error) => {
    console.log(error);
};

const handleCredits = async (response) => {
// calling this API again upon form submit (generate button click)
  fetch("https://rizz-ai-backend.onrender.com/credits", {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    }, 
    body: JSON.stringify({ user: user })
  })
  .then((response) => response.json())
  .then((json) => {
    console.log('Credits are: ', json.creditsRemaining)
    setCredits(json.creditsRemaining);
    console.log('user is: ', user)
  
  });
}

const handleSubmit = (event) => {
    setLoading(true);
    event.preventDefault();
    console.log(interests);
    fetch("https://rizz-ai-backend.onrender.com/result", {
      method: 'POST',
      // mode: 'no-cors',
      headers: {
        'Content-Type': 'application/json',
      }, 
      body: JSON.stringify({ interests: interests, user: user, plan: plan, credits: credits })
    }).then(setTimeout(() => {
      setLoading(false);
    }, 3000)).then((response) => response.json())
    .then((json) => {
      // console.log(json.text)
      setCredits(prevCredits => prevCredits - 1)
      setResult(json.text) 
    });
  };

// this calls the GET API for lemon squeezy checkout link - called in line 124
    const handlePaymentClick = async () => {
        try {
          console.log('reached handle')
          fetch('https://rizz-ai-backend.onrender.com/initiate-payment', {
                method: 'GET',
                headers: {
                  'Content-Type': 'application/json',
                }
              }).then((response) => response.json()).then((json) => {
                window.location.href = json.checkoutUrl;
              });
        }
        catch (error) {
            console.error('Error initiating payment:', error);
        }
    };

  if(!user) {
    condition = <div className="bg-black min-h-screen flex flex-col justify-center items-center">
                    <img src="/rizz-new.png" alt="Placeholder logo" className="h-20 mb-8"/>
                    <label htmlFor="interests" className="text-center p-4 text-white w-3/4 mb-2 font-mono text-4xl">cool one-liners to strike conversations on dating apps based on their interest!</label>
                    <br/>
                    <GoogleLogin onSuccess={responseMessage} onError={errorMessage} />
                </div>
  }

  else {

    if(plan == "free") { 
      condition = <div>
        <div className="bg-black min-h-screen flex flex-col justify-center items-center">
        <img src="/rizz-new.png" alt="Placeholder logo" className="h-20 mb-8"/>
        <label htmlFor="interests" className="text-center p-4 text-white w-3/4 mb-2 font-mono text-4xl">cool one-liners to strike conversations on dating apps based on their interest!</label>
        <br/>

        {
          credits === 0 ? (
            <>
              <p className="text-center p-4 text-white w-3/4 mb-2 font-mono text-md">You have exhausted your free credits!</p>
              <button 
              className="bg-red-500 hover:bg-red-600 text-white font-mono py-2 px-4 rounded"
              onClick={handlePaymentClick}>
                  Upgrade Plan
              </button>
            </>
          ) : 
          <>
          <p className="text-center p-4 text-white w-3/4 mb-2 font-mono text-md">{credits} free credits remaining</p>
          <form onSubmit={handleSubmit} className="bg-gray-400 rounded-md p-8 shadow-lg">
            <label className="block text-gray-700 font-mono mb-2">
            enter their interest
            </label>

            <textarea
                id="interests"
                name="interests"
                className="border bg-gray-200 rounded-md font-mono py-2 px-3 mb-4 w-full"
                placeholder="e.g. eminem"
                value={interests}
                onChange={(event) => setInterests(event.target.value)}
                
              />
            <button
              type="submit"
              className="bg-red-500 hover:bg-red-600 text-white font-mono py-2 px-4 rounded"
            >
              generate
            </button>
            {loading && (
            <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-800 bg-opacity-75">
              <div className="loader animate-spin ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12 duration-500"></div>
            </div>
          )}
          </form>
          </> 
        }
        <br/>
        <label htmlFor="interests" className="text-center text-white mb-2 p-2 font-mono w-3/4 font-semibold text-xl">{result}</label>

      </div>
      </div>
    }

    else {
      condition = <div>
        <div className="bg-black min-h-screen flex flex-col justify-center items-center">
        <img src="/rizz-new.png" alt="Placeholder logo" className="h-20 mb-8"/>
        <label htmlFor="interests" className="text-center p-4 text-white w-3/4 mb-2 font-mono text-4xl">cool one-liners to strike conversations on dating apps based on their interest!</label>
        <br/>
        <>
          <form onSubmit={handleSubmit} className="bg-gray-400 rounded-md p-8 shadow-lg">
            <label className="block text-gray-700 font-mono mb-2">
            enter their interest
            </label>

            <textarea
                id="interests"
                name="interests"
                className="border bg-gray-200 rounded-md font-mono py-2 px-3 mb-4 w-full"
                placeholder="e.g. eminem"
                value={interests}
                onChange={(event) => setInterests(event.target.value)}
                
              />
            <button
              type="submit"
              className="bg-red-500 hover:bg-red-600 text-white font-mono py-2 px-4 rounded"
            >
              generate
            </button>
            {loading && (
            <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-800 bg-opacity-75">
              <div className="loader animate-spin ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12 duration-500"></div>
            </div>
          )}
          </form>
          </> 
        <br/>
        <label htmlFor="interests" className="text-center text-white mb-2 p-2 font-mono w-3/4 font-semibold text-xl">{result}</label>

      </div>
      </div>
    }
  
  }

  return (
  <div> 
    {condition}
  </div>
  );
};

export default InterestInput;
