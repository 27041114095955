import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { GoogleOAuthProvider } from '@react-oauth/google';
import InterestInput from './App';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <div>
    <GoogleOAuthProvider clientId="303427684128-417k8q741iu57rjru5baefe2ei0kj415.apps.googleusercontent.com">
  <React.StrictMode>
    <InterestInput />
  </React.StrictMode>
  </GoogleOAuthProvider>
  </div>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
